'use strict'

###*
 # @ngdoc service
 # @name mundoUtils.factory:FileSaver

 # @description

###
angular
  .module 'mundoUtils'
  .factory 'FileSaver', [
    '$log'
    ($log) ->
      FileSaverBase = {}

      FileSaverBase.saveFromResponse = (response, fileName = null, contentType = null, data = null) ->
        if not fileName?
          disposition = response.headers 'content-disposition'
          fileName = disposition.substr (disposition.indexOf('filename=') + 9)
          fileName = fileName.replace /^"|"$/g, ''

        if not contentType?
          contentType = response.headers 'content-type'

        if not data?
          data = response.data

        blob = new Blob [data],
          type: contentType

        length = if data.byteLength? then data.byteLength else null
        length ?= if data.length? then data.length else null

        $log.debug "FileSaver: Saving '#{length}' bytes of data as '#{fileName}'"
        saveAs blob, fileName

      FileSaverBase
  ]
